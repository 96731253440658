import React from "react";
import "./header.scss";
import logo from "../../assets/images/logo.png";
import address from "../../assets/images/address-red.svg";
import call from "../../assets/images/call-red.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <Link to="https://goo.gl/maps/DCsD3M89QmYbfx3q6" target="_blank" className="address">
        <img src={address} alt="location-icon" />
        <p>
          Located in Walmart Supercenter 210 Cobb Parkway S Marietta GA 30060
        </p>
      </Link>
      <div className="logo">
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <Link to="tel:+17704220211" className="number">
        <img src={call} alt="call-icon" />
        <p>(770) 422-0211</p>
      </Link>
    </div>
  );
};

export default Header;
