import React from "react";
import "./nail-insights.scss";
import b1 from "../../assets/images/blogs/b1.jpg";
import arrow from "../../assets/images/arrow.svg";
import { Link } from "react-router-dom";
import { blogs } from "../../assets/data/blogs";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";

const NailInsights = () => {
  return (
    <>
      <Helmet title={"Nail Insights"} canonical={"https://walmartnails.com/nail-insights"} />
      <CommonSection title={"Nail Insights"} useAsHeading={true} />
      <div className="nail-insights">
        <div className="bg-pattern"></div>
        <h3>latest blogs</h3>

        <div className="all-blogs">
          {blogs.map((item, index) => (
            <div className="blog-card">
              <img src={item.thumbnail} alt="thumbnail" />
              <p className="date">
                Date . <span>{item.postDate}</span>
              </p>
              <p className="title">{item.title}</p>
              <p className="short-desc">{item.shortDesc}</p>
              <Link to={item.id}>
                <img src={arrow} alt="arrow-icon" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NailInsights;
