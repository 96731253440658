import React from "react";
import "./services.scss";
import CommonSection from "../../components/common-section/CommonSection";
import servicesSideimg from "../../assets/images/services-sideimg.webp";
import circles from "../../assets/images/circles.svg";
import spikes from "../../assets/images/spikes.svg";
import quad from "../../assets/images/quad.svg";
import ServiceCard from "../../components/service-card/ServiceCard";
import { services } from "../../assets/data/services";
import ServiceCard2 from "../../components/service-card/ServiceCard2";
import ContactSection from "../../components/contact-section/ContactSection";
import TestimonialSection from "../../components/testimonials-section/TestimonialSection";
import Helmet from "../../components/helmet/Helmet";

const Services = () => {
  const manicureServices = services.filter(
    (item) => item.id === "manicure-service"
  );
  const pedicureServices = services.filter(
    (item) => item.id === "pedicure-service"
  );
  const additionalServices = services.filter(
    (item) => item.id === "additional-service"
  );
  return (
    <div className="services">
      <Helmet
        title={"Best Pedicure & Manicure in Marietta, Georgia"}
        desc={
          "We offer the best pedicure, manicure, acrylic full set and gel pedicure service in Marietta, Georgia. Call us at (770) 422-0211 to book your session"
        }
        canonical={"https://walmartnails.com/services"}
      />
      <CommonSection title={"Services"} />

      {/* SECTION-1 */}
      <div className="section s-section">
        <div className="bg-pattern"></div>
        <div className="left">
          <img src={servicesSideimg} alt="treatment" />
          <img src={circles} alt="circles" className="icon" />
        </div>

        <div className="right">
          <h1 className="title">Best Value Pedicure, Manicure in Marietta</h1>
          <div className="desc">
            At our salon, we offer a wide variety of services to pamper and
            beautify your nails. From classic manicures and pedicures to the
            latest trends in gel and chrome nails, our skilled technicians are
            dedicated to providing you with a luxurious experience and making
            your nails look and feel their best. You can't beat our prices!
          </div>
          <div className="bottom">
            <p>No Appointment Needed - Walk-Ins Welcome</p>
          </div>
        </div>

        <img src={spikes} alt="spikes-icon" />
        <img src={quad} alt="quad-icon" />
      </div>

      {/* ALL SERVICES START */}

      {/* Manicure */}
      <div className="manicure-services">
        <div className="bg-pattern"></div>
        <div className="bg-pattern2"></div>
        <h2>MANICURE SERVICES</h2>
        {manicureServices.map((item, index) => (
          <ServiceCard
            img={item.img}
            name={item.name}
            desc={item.desc}
            price={item.price}
          />
        ))}

        <img src={spikes} alt="spikes-icon" />
        <img src={quad} alt="quad-icon" />
      </div>

      {/* Pedicure */}
      <div className="pedicure-services">
        <div className="content">
          <h2>PEDICURE SERVICES</h2>
          {pedicureServices.map((item, index) => (
            <ServiceCard
              img={item.img}
              name={item.name}
              desc={item.desc}
              price={item.price}
            />
          ))}
        </div>
      </div>

      {/* Additional Services */}
      <div className="additional-services">
        <div className="bg-pattern"></div>
        <h2>ADDITIONAL SERVICES</h2>
        {additionalServices.map((item, index) => (
          <ServiceCard2 img={item.img} name={item.name} price={item.price} />
        ))}

        <img src={quad} alt="quad-icon" />
      </div>

      {/* ALL SERVICES END */}

      <ContactSection />
      <TestimonialSection />
    </div>
  );
};

export default Services;
