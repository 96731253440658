export const menuLink = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "Services",
        path: "/services"
    },
    {
        name: "Our Story",
        path: "/our-story"
    },
    {
        name: "Testimonials",
        path: "/testimonials"
    },
    {
        name: "Nail Insights",
        path: "/nail-insights"
    },
    {
        name: "Contact Us",
        path: "/contact-us"
    }
]