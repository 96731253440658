import b1 from "../images/blogs/b1.jpg"
import b2 from "../images/blogs/b2.jpg"
import b3 from "../images/blogs/b3.jpg"
import bd1 from "../images/blogs/bd1.jpg"

export const blogs = [
    {
        id: "top-10-ways-to-protect-your-nails-to-ensure-long-lasting-beauty",
        metaDesc: 'Here are the op 10 ways that will help you to protect your nails. These are tried and tested tips that will help in maintaining the beauty of your nails',
        thumbnail: b1,
        img: bd1,
        title: "Top 10 Ways to Protect Your Nails to Ensure Long Lasting Beauty",
        postDate: "Aug 05",
        name: "davinailsmarietta",
        shortDesc: "To best protect your beautiful nails after a manicure and ensure the longevity of your nail art, follow these tips: Be Cautious Right... ",
        startingLine: "To best protect your beautiful nails after a manicure and ensure the longevity of your nail art, follow these tips:",
        points: [
            {
                bold: "Be Cautious Right After the Manicure:",
                normal: "Allow your nails ample time to dry completely after the manicure. Avoid touching or smudging the polish during this time."
            },
            {
                bold: "Use Gloves:",
                normal: "When doing household chores or any activities that involve water, chemicals, or potential nail stress, wear protective gloves. This prevents chipping, lifting, and damage to your manicure."
            },
            {
                bold: "Moisturize Regularly:",
                normal: "Keep your nails and cuticles hydrated by applying cuticle oil or hand cream regularly. This prevents the nails from becoming dry and brittle, preserving the manicure."
            },
            {
                bold: "Avoid Harsh Chemicals:",
                normal: "Stay away from products that contain harsh chemicals or solvents, as they can weaken or dissolve the nail polish."
            },
            {
                bold: "Use Non-Acetone Remover:",
                normal: "When it's time to remove your nail polish, use a non-acetone nail polish remover. Acetone-based removers can be drying and damaging to the nails."
            },
            {
                bold: "Reapply Topcoat:",
                normal: "To refresh and protect your manicure, apply a thin layer of topcoat every few days. This helps seal the polish and adds extra shine and durability."
            },
            {
                bold: "Avoid Nail Biting or Picking:",
                normal: "Resist the temptation to bite or pick at your nails, as this can cause chipping and ruin your manicure."
            },
            {
                bold: "Be Gentle with Your Nails:",
                normal: "Be mindful of your nails in your daily activities. Avoid using them as tools or subjecting them to unnecessary stress."
            },
            {
                bold: "Schedule Regular Nail Maintenance:",
                normal: "Book regular nail appointments for maintenance, including fills or touch-ups, to keep your manicure looking fresh."
            },
            {
                bold: "Consider Gel or Dip Powder Manicures:",
                normal: "If you want a longer-lasting manicure, consider opting for gel or dip powder nails, as they tend to be more durable and chip-resistant."
            }
        ],
        closingLine1: "By taking these precautions and maintaining proper nail care,",
        closingLine2: "you can enjoy your beautiful manicure for an extended period while keeping your nails healthy and strong.",
    },
    // {
    //     id: "ways-to-protect-nails",
    //     thumbnail: b2,
    //     img: bd1,
    //     title: "Top 10 Ways to Protect Your Nails to Ensure Long Lasting Beauty",
    //     postDate: "Aug 05",
    //     name: "davinailsmarietta",
    //     shortDesc: "To best protect your beautiful nails after a manicure and ensure the longevity of your nail art, follow these tips: Be Cautious Right... ",
    //     startingLine: "To best protect your beautiful nails after a manicure and ensure the longevity of your nail art, follow these tips:",
    //     points: [
    //         {
    //             bold: "Be Cautious Right After the Manicure:",
    //             normal: "Allow your nails ample time to dry completely after the manicure. Avoid touching or smudging the polish during this time."
    //         },
    //         {
    //             bold: "Use Gloves:",
    //             normal: "When doing household chores or any activities that involve water, chemicals, or potential nail stress, wear protective gloves. This prevents chipping, lifting, and damage to your manicure."
    //         },
    //         {
    //             bold: "Moisturize Regularly:",
    //             normal: "Keep your nails and cuticles hydrated by applying cuticle oil or hand cream regularly. This prevents the nails from becoming dry and brittle, preserving the manicure."
    //         },
    //         {
    //             bold: "Avoid Harsh Chemicals:",
    //             normal: "Stay away from products that contain harsh chemicals or solvents, as they can weaken or dissolve the nail polish."
    //         },
    //         {
    //             bold: "Use Non-Acetone Remover:",
    //             normal: "When it's time to remove your nail polish, use a non-acetone nail polish remover. Acetone-based removers can be drying and damaging to the nails."
    //         },
    //         {
    //             bold: "Reapply Topcoat:",
    //             normal: "To refresh and protect your manicure, apply a thin layer of topcoat every few days. This helps seal the polish and adds extra shine and durability."
    //         },
    //         {
    //             bold: "Avoid Nail Biting or Picking:",
    //             normal: "Resist the temptation to bite or pick at your nails, as this can cause chipping and ruin your manicure."
    //         },
    //         {
    //             bold: "Be Gentle with Your Nails:",
    //             normal: "Be mindful of your nails in your daily activities. Avoid using them as tools or subjecting them to unnecessary stress."
    //         },
    //         {
    //             bold: "Schedule Regular Nail Maintenance:",
    //             normal: "Book regular nail appointments for maintenance, including fills or touch-ups, to keep your manicure looking fresh."
    //         },
    //         {
    //             bold: "Consider Gel or Dip Powder Manicures:",
    //             normal: "If you want a longer-lasting manicure, consider opting for gel or dip powder nails, as they tend to be more durable and chip-resistant."
    //         }
    //     ],
    //     closingLine1: "By taking these precautions and maintaining proper nail care,",
    //     closingLine2: "you can enjoy your beautiful manicure for an extended period while keeping your nails healthy and strong.",
    // },
    // {
    //     id: "ways-to-protect-nails",
    //     thumbnail: b3,
    //     img: bd1,
    //     title: "Top 10 Ways to Protect Your Nails to Ensure Long Lasting Beauty",
    //     postDate: "Aug 05",
    //     name: "davinailsmarietta",
    //     shortDesc: "To best protect your beautiful nails after a manicure and ensure the longevity of your nail art, follow these tips: Be Cautious Right... ",
    //     startingLine: "To best protect your beautiful nails after a manicure and ensure the longevity of your nail art, follow these tips:",
    //     points: [
    //         {
    //             bold: "Be Cautious Right After the Manicure:",
    //             normal: "Allow your nails ample time to dry completely after the manicure. Avoid touching or smudging the polish during this time."
    //         },
    //         {
    //             bold: "Use Gloves:",
    //             normal: "When doing household chores or any activities that involve water, chemicals, or potential nail stress, wear protective gloves. This prevents chipping, lifting, and damage to your manicure."
    //         },
    //         {
    //             bold: "Moisturize Regularly:",
    //             normal: "Keep your nails and cuticles hydrated by applying cuticle oil or hand cream regularly. This prevents the nails from becoming dry and brittle, preserving the manicure."
    //         },
    //         {
    //             bold: "Avoid Harsh Chemicals:",
    //             normal: "Stay away from products that contain harsh chemicals or solvents, as they can weaken or dissolve the nail polish."
    //         },
    //         {
    //             bold: "Use Non-Acetone Remover:",
    //             normal: "When it's time to remove your nail polish, use a non-acetone nail polish remover. Acetone-based removers can be drying and damaging to the nails."
    //         },
    //         {
    //             bold: "Reapply Topcoat:",
    //             normal: "To refresh and protect your manicure, apply a thin layer of topcoat every few days. This helps seal the polish and adds extra shine and durability."
    //         },
    //         {
    //             bold: "Avoid Nail Biting or Picking:",
    //             normal: "Resist the temptation to bite or pick at your nails, as this can cause chipping and ruin your manicure."
    //         },
    //         {
    //             bold: "Be Gentle with Your Nails:",
    //             normal: "Be mindful of your nails in your daily activities. Avoid using them as tools or subjecting them to unnecessary stress."
    //         },
    //         {
    //             bold: "Schedule Regular Nail Maintenance:",
    //             normal: "Book regular nail appointments for maintenance, including fills or touch-ups, to keep your manicure looking fresh."
    //         },
    //         {
    //             bold: "Consider Gel or Dip Powder Manicures:",
    //             normal: "If you want a longer-lasting manicure, consider opting for gel or dip powder nails, as they tend to be more durable and chip-resistant."
    //         }
    //     ],
    //     closingLine1: "By taking these precautions and maintaining proper nail care,",
    //     closingLine2: "you can enjoy your beautiful manicure for an extended period while keeping your nails healthy and strong.",
    // },
]