import React, { useRef } from "react";
import "./footer.scss";
import logo from "../../assets/images/logo.png";
import { menuLink } from "../../assets/data/menuLinks";
import { Link } from "react-router-dom";
import call from "../../assets/images/call.svg";
import mail from "../../assets/images/mail.svg";
import location from "../../assets/images/location.svg";
import insta from "../../assets/images/insta.svg";
import youtube from "../../assets/images/youtube.svg";
import tw from "../../assets/images/tw.svg";
import fb from "../../assets/images/fb.svg";
import quad from "../../assets/images/quad.svg";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_on5nidn",
        "template_qwipmbh",
        form.current,
        "bHKmzUvcOZ1zl1Pnw"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Email Sent Successfully!",
            text: "Thank You For Contacting Us! Our team will be in touch with you soon.",
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000,
          });
          form.current.reset();
        },
        (error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Try Again!",
            text: "Something went wrong, Please Try Again",
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      );
  };

  return (
    <>
      <div className="footer">
        <img src={quad} alt="right-quad-icon" />

        <div className="newsletter">
          <h3>Stay Updated</h3>
          <form
            action=""
            className="newsletter-form"
            ref={form}
            onSubmit={sendEmail}
          >
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email Address"
            />
            <button type="submit" id="submit-btn">
              Subscribe
            </button>
          </form>
        </div>

        <div className="main-footer">
          <div className="company">
            <img src={logo} alt="logo" />
            <p>
              Visiting our nail salon is a great way to pamper yourself and feel
              refreshed at a fraction of the cost of other salons. You can
              choose from a variety of services,including manicures, pedicures
            </p>
            <ul className="social-links">
              <li>
                <Link
                  to={
                    "https://www.instagram.com/davinailsga/?fbclid=IwAR1vYCB1lmTlLNzJEIdziPEJ0X-hX6wUUMw36BlxDjUN4czmKY485rSsHew                  "
                  }
                  target="_blank"
                >
                  <img src={insta} alt="insta" />
                </Link>
              </li>
              <li>
                <Link to={"https://twitter.com/DaviMarietta"} target="_blank">
                  <img src={tw} alt="tw" />
                </Link>
              </li>
              <li>
                <Link to={"https://www.facebook.com/DaviNailsMarietta"} target="_blank">
                  <img src={fb} alt="fb" />
                </Link>
              </li>
            </ul>
          </div>

          <div className="quick-links">
            <h3>Quick Links</h3>
            <ul>
              {menuLink.map((item, index) => (
                <li>
                  <Link to={item.path}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="opening-hours">
            <h3>Opening Hours</h3>
            <p>Mon-Sat: 10:00am - 7:00pm</p>
            <p>Sun: 12:00pm - 6:00pm</p>
          </div>

          <div className="contact-us">
            <h3>Contact Us</h3>
            <Link to="tel:+17704220211" className="call">
              <img src={call} alt="call-icon" />
              <p>770-422-0211</p>
            </Link>
            <div className="mail">
              <img src={mail} alt="mail-icon" />
              <p>DaviNailsMarietta@Outlook.com</p>
            </div>
            <Link
              to="https://goo.gl/maps/DCsD3M89QmYbfx3q6"
              target="_blank"
              className="location"
            >
              <img src={location} alt="location-icon" />
              <p>
                Located in Walmart Supercenter 210 Cobb Parkway S Marietta GA
                30060
              </p>
            </Link>
          </div>
        </div>

        <img src={quad} alt="left-quad-icon" />
      </div>

      <div className="copyright">
        ©Copyright 2023 DAVI NAILS All Right Reserved.
      </div>
    </>
  );
};

export default Footer;
