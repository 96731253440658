import React from "react";
import "./style.scss";
import CommonSection from "../../components/common-section/CommonSection";
import manicure from "../../assets/images/manicure.webp";
import pedicure from "../../assets/images/pedicure.webp";
import acrylic from "../../assets/images/acrylic.png";
import TreatmentCard from "../../components/treatments-card/TreatmentCard";
import { Link } from "react-router-dom";
import off1Sideimg from "../../assets/images/off1-sideimg.png";
import spring from "../../assets/images/spring.svg";

const Smyrna = () => {
  const treatmentCards = [
    {
      title: "Manicure",
      desc: "If you are looking for a manicure service in Kennesaw, then you are at the right place. At Davi Nails, we offer best manicure service that will give you the best look for your nails",
      img: manicure,
    },
    {
      title: "Pedicure",
      desc: "If you are looking for a relaxing pedicure, our team at Davi Nails offers one of the best pedicures in Marietta and Kennesaw. our pedicure process will help you unwind and pamper your feet",
      img: pedicure,
    },
    {
      title: "​Acrylic Full set",
      desc: "​If you're looking for a stylish nail enhancement option, a full set of acrylic nails may be perfect for you! At Davi Nails,  we offer a variety of acrylic nail options to help you achieve the perfect look for your nails.",
      img: acrylic,
    },
  ];

  return (
    <>
      <CommonSection title={"Smyrna"} />

      <div className="smyrna">
        <div className="head">
          <h1>Best Value Nail Salon - Just 5 minutes drive from Smyrna</h1>
          <p className="description">
            Welcome to Davi Nails! We are located in Marietta, just 5 minute's
            drive from Smyrna. We are very excited to offer our nail services to
            the people of Smyrna. Located just a few miles away from Kennesaw,
            we have highly skilled and friendly technicians who have decades of
            experience in providing top-notch nail service at an affordable
            price. Our salon is clean, comfortable, and designed to help you
            feel relaxed and rejuvenated during your visit. We use only the
            highest quality products and ensure that you receive the best
            possible service, whether you're looking for a quick manicure or a
            full set of acrylic nails. Best of all, with prices rising
            significantly we offer a great alternative at an affordable price.
          </p>
        </div>

        <div className="our-treatments-section">
          <div className="heading">nail care Services we offer</div>
          <div className="sect">
            {treatmentCards.map((item, index) => (
              <TreatmentCard
                img={item.img}
                title={item.title}
                desc={item.desc}
              />
            ))}
          </div>
          <button>
            <Link to={"/services"}>All Services</Link>
          </button>

          <div className="bg-pattern"></div>
        </div>

        <div className="why-choose-us">
          <h3>why choose us</h3>
          <p className="description">
            At Davi Nails, we understand that you have many options when it
            comes to nail salons in the Kennesaw and Marietta areas. Here are
            just a few reasons why we believe you should choose us for your next
            nail care experience:
          </p>

          {/* SECTION-1 */}
          <div className="section section-1">
            <div className="left">
              <img src={off1Sideimg} alt="beautiful nails" />
              <img className="icon" src={spring} alt="spring-icon" />
            </div>

            <div className="right">
              <div>
                <h5 className="title">Skilled Technicians</h5>
                <p className="desc">
                  Our technicians are highly skilled and experienced in all
                  aspects of nail care, including manicures, pedicures,
                  acrylics, and more.
                </p>
              </div>

              <div>
                <h5 className="title">High-Quality Products</h5>
                <p className="desc">
                  We understand the importance of quality. At Davi Nails, we
                  only use high quality nail polish, acrylics and other nail
                  care products
                </p>
              </div>
              <div>
                <h5 className="title">Available all 365 days</h5>
                <p className="desc">
                  Yes, we are open all 7 days a week, so you can book your
                  appointment at your convenient time.
                </p>
              </div>
              <div>
                <h5 className="title">Affordable Prices</h5>
                <p className="desc">
                  We believe that everyone deserves access to great nail care
                  services at affordable prices. We offer competitive pricing on
                  all of our services.
                </p>
              </div>
              <div>
                <h5 className="title">Outstanding customer service</h5>
                <p className="desc">
                  We ensure that our customers are always satisfied. We work
                  hard to provide exceptional results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Smyrna;
