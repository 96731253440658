import React from "react";
import "./our-story.scss";
import CommonSection from "../../components/common-section/CommonSection";
import aboutSideimg from "../../assets/images/about-sideimg.webp";
import massageRedirect from "../../assets/images/massage-redirect.png";
import circles from "../../assets/images/circles.svg";
import { Link } from "react-router-dom";
import CtaSection from "../../components/cta-section/CtaSection";
import TestimonialSection from "../../components/testimonials-section/TestimonialSection";
import quad from "../../assets/images/quad.svg";
import spikes from "../../assets/images/spikes.svg";
import Helmet from "../../components/helmet/Helmet";

const OurStory = () => {
  return (
    <div className="our-story">
      <Helmet
        title={"About Davi Nails"}
        desc={
          "With so many 5 star reviews on Google and why we have been in business for over 15 years!! We are the most most affordable nail salon in Georgia"
        }
        canonical={"https://walmartnails.com/our-story"}
      />
      <CommonSection title={"Our Story"} useAsHeading={true} />

      {/* SECTION-1 */}
      <div className="section section-3">
        <div className="left">
          <img src={aboutSideimg} alt="nails" />
          <img className="icon" src={circles} alt="circles-icon" />
          <img src={spikes} alt="spikes-icon" />
        </div>

        <div className="right">
          <h2 className="title">who we are</h2>
          <p className="desc">
            Come see why we have so many 5 star reviews on Google and why we
            have been in business for over 15 years!!! The best most affordable
            salon in Atlanta.
            <span>
              Come to our salon and experience the ultimate pampering for your
              nails. From classic manicures and pedicures to the latest trends
              in gel and chrome nails, our skilled technicians will have your
              hands and feet looking and feeling their best. We pride ourselves
              on using only the highest-quality products and providing excellent
              service to ensure our clients leave satisfied and happy every
              time. Whether you're looking for a relaxing day or want to try
              something new and trendy, we have something for everyone.
            </span>
          </p>
          <div className="bottom">
            <p
              style={{
                textDecoration: "underline",
                fontWeight: 600,
                width: "100%",
              }}
            >
              No Appointment Needed - Walk-Ins Welcome
            </p>
          </div>
        </div>

        <div className="bg-pattern"></div>
        <img src={quad} alt="quad-icon" />
      </div>

      {/* SECTION-2 */}
      <div className="massage-redirect-section">
        <div className="image">
          <Link to={"https://www.massagenow-atl.com/"} target="_blank">
            <img src={massageRedirect} alt="massage-now" />
          </Link>
        </div>
        <p className="text">
          <Link to={"https://www.massagenow-atl.com/"} target="_blank">
            Don't forget to also improve your well being and also visit the #1
            voted massage therapy business in Georgia near you at Massage Now!
            in Kennesaw
          </Link>
        </p>

        <img src={spikes} alt="spikes-icon" />
      </div>

      <CtaSection />
      <TestimonialSection />
    </div>
  );
};

export default OurStory;
