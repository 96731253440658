import React from 'react'
import "./service-card2.scss"

const ServiceCard2 = ({img, name, price}) => {
  return (
    <div className="service-card2">
        <img src={img} alt="" />
        <h4>{name}</h4>
        <p className="price">{price}</p>
    </div>
  )
}

export default ServiceCard2