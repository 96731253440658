import React, { Suspense } from "react";
import "./home.scss";
import Hero from "../../components/hero/Hero";
import sectionOneSideImage from "../../assets/images/section-one-sideimage.webp";
import sectionTwoSideImage from "../../assets/images/section-two-sideimg.webp";
import sectionThreeSideImage from "../../assets/images/section-three-sideimg.webp";
import sectionFourSideImage from "../../assets/images/section-four-sideimg.webp";
import google from "../../assets/images/google.webp";
import { Link } from "react-router-dom";
import spring from "../../assets/images/spring.svg";
import circles from "../../assets/images/circles.svg";
import cross from "../../assets/images/cross.svg";
import quad from "../../assets/images/quad.svg";
import tick from "../../assets/images/tick.svg";
import leafLeft from "../../assets/images/leaf-right.svg";
import leafRight from "../../assets/images/leaf-left.svg";
import spikes from "../../assets/images/spikes.svg";
import Helmet from "../../components/helmet/Helmet";
const CtaSection = React.lazy(() =>
  import("../../components/cta-section/CtaSection")
);
const ContactSection = React.lazy(() =>
  import("../../components/contact-section/ContactSection")
);
const TestimonialSection = React.lazy(() =>
  import("../../components/testimonials-section/TestimonialSection")
);
const OurTreatmentsSection = React.lazy(() =>
  import("../../components/our-treatments-section/OurTreatmentsSection")
);

const Home = () => {
  return (
    <div>
      <Helmet
        title={"Top Rated Nail Salon & Spa in Marietta, GA"}
        desc={
          "Get yourself pampered and refreshed at one of the best nail salon in Marietta, Georgia. We offer complete nail spa with skilled technicians. Call us at (770) 422-0211"
        }
        canonical={"https://walmartnails.com"}
      />

      <Hero />
      <Suspense fallback={<div>Loading...</div>}>
        <OurTreatmentsSection />
      </Suspense>

      {/* SECTION-1 */}
      <div className="section section-1">
        <div className="left">
          <img src={sectionOneSideImage} alt="flower in hand" />
          <img className="icon" src={spring} alt="spring-icon" />
        </div>

        <div className="right">
          <div className="label">By Trusted Nail Artists</div>
          <h2 className="title">The Go-To Nail Salon Best Value in Atlanta</h2>
          <p className="desc">
            Visiting our nail salon is a great way to pamper yourself and feel
            refreshed at a fraction of the cost of other salons. You can choose
            from a variety of services, including manicures, pedicures, and nail
            art, and our skilled technicians will ensure that you leave with
            beautiful, polished nails. Plus, our salon uses high-quality
            products and implements strict sanitation protocols to ensure a safe
            and relaxing experience for all of our clients.
          </p>
          <div className="bottom">
            <button>
              <Link to={"/our-story"}>Read More</Link>
            </button>
            <img src={google} alt="google-reviews" />
          </div>
        </div>

        <img src={leafRight} alt="leaf" className="leaf-icon-right" />
        <img src={spikes} alt="spike-left" />
        <img src={spikes} alt="spike-right" />
      </div>

      {/* SECTION-2 */}
      <div className="section section-2">
        <div className="left">
          <img src={sectionTwoSideImage} alt="nail-polish" />
          <img className="icon" src={cross} alt="cross-icon" />
        </div>

        <div className="right">
          <div className="label">Top Quality</div>
          <div className="title">Our gel nails guarantee</div>
          <h2 className="desc">
            There are many nail treatments to choose from, all of which will
            leave you with a radiant smile on your face as well as healthy and
            fabulous nails. Do you have a hard time making a decision? We are
            happy to offer advice at the best nail salon!
          </h2>
          <div className="bottom">
            <h3>7 Days</h3>
            <p>Walk-in's Welcome / No Appointment Needed</p>
          </div>
        </div>

        <img src={leafLeft} alt="leaf" className="leaf-icon-left" />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <CtaSection />
      </Suspense>

      {/* SECTION-3 */}
      <div className="section section-3">
        <div className="left">
          <img src={sectionThreeSideImage} alt="beautiful-nails" />
          <img className="icon" src={circles} alt="circles-icon" />
        </div>

        <div className="right">
          <div className="label">The Best Team</div>
          <div className="title">WHY CHOOSE OUR NAIL SALON?</div>
          <ul className="desc">
            <li>
              <img src={tick} alt="tick-icon" />
              <p>We are open 7 days a week</p>
            </li>
            <li>
              <img src={tick} alt="tick-icon" />
              <p>Our prices are the best in town</p>
            </li>
            <li>
              <img src={tick} alt="tick-icon" />
              <p>
                We have a team of highly trained and qualified professionals
              </p>
            </li>
            <li>
              <img src={tick} alt="tick-icon" />
              <p>
                We offer a wide range of nail services that are perfect for you
              </p>
            </li>
            <li>
              <img src={tick} alt="tick-icon" />
              <p>We provide outstanding customer service all the time</p>
            </li>
            <li>
              <img src={tick} alt="tick-icon" />
              <p>We work hard to deliver results that exceed expectations</p>
            </li>
          </ul>
          <div className="bottom">
            <img src={google} alt="google-reviews" />
            <p>Walk-in's Welcome / No Appointment Needed</p>
          </div>
          <img className="icon" src={quad} alt="quad-icon" />
        </div>

        <img src={leafRight} alt="leaf" className="leaf-icon-right" />
      </div>

      {/* SECTION-4 */}
      <div className="section section-4">
        <div className="left">
          <img src={sectionFourSideImage} alt="flower-in-hand" />
          <img className="icon" src={spring} alt="spring-icon" />
        </div>

        <div className="right">
          <div className="label">Our Story</div>
          <div className="title">WHO WE ARE</div>
          <div className="desc">
            Come see why we have so many 5 star reviews on Google and why we
            have been in business for over 15 years!!! The best most affordable
            salon in Atlanta.
            <span>
              Come to our salon and experience the ultimate pampering for your
              nails. From classic manicures and pedicures to the latest trends
              in gel and chrome nails, our skilled technicians will have your
              hands and feet looking and feeling their best. We pride ourselves
              on using only the highest-quality products and providing excellent
              service to ensure our clients leave satisfied and happy every
              time.
            </span>
          </div>
          <div className="bottom">
            <button>
              <Link to={"/our-story"}>Read More</Link>
            </button>
            <img src={google} alt="google-reviews" />
          </div>
        </div>

        <img src={leafLeft} alt="leaf" className="leaf-icon-left" />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <ContactSection />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <TestimonialSection />
      </Suspense>
    </div>
  );
};

export default Home;
