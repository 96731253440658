import React from "react";
import "./cta-section.scss";
import { Link } from "react-router-dom/dist";
import ctaVector from "../../assets/images/cta-vector.webp"

const CtaSection = () => {
  return (
    <div className="cta-section">
      <div className="left">
        <img src={ctaVector} alt="vector-nails" />
      </div>
      <div className="right">
        <h3>CONTACT DAVI NAILS TODAY TO ENJOY OUR SERVICES</h3>
        <button><Link to={"/contact-us"}>Contact Us</Link></button>
      </div>
    </div>
  );
};

export default CtaSection;
