import React, { useRef } from "react";
import contactSideimg from "../../assets/images/contact-sideimg.webp";
import CommonSection from "../../components/common-section/CommonSection";
import "./contact.scss";
import call from "../../assets/images/call.svg";
import mail from "../../assets/images/mail.svg";
import location from "../../assets/images/location.svg";
import quad from "../../assets/images/quad.svg";
import spikes from "../../assets/images/spikes.svg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import Helmet from "../../components/helmet/Helmet";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_on5nidn",
        "template_qwipmbh",
        form.current,
        "bHKmzUvcOZ1zl1Pnw"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Email Sent Successfully!",
            text: "Thank You For Contacting Us! Our team will be in touch with you soon.",
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000,
          });
          form.current.reset();
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Try Again!",
            text: "Something went wrong, Please Try Again",
            showCloseButton: true,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      );
  };

  return (
    <div className="contact">
      <Helmet
        title={"Contact Davi Nails"}
        desc={
          "Looking to experience the best nail spa in Marietta, Georgia? Call us now at (770) 422-0211 to pamper you nails"
        }
        canonical={"https://walmartnails.com/contact-us"}
      />
      <CommonSection title={"Contact Us"} useAsHeading={true} />

      {/* SECTION-1 */}
      <div className="section section-3 c-section">
        <div className="bg-pattern"></div>
        <div className="left">
          <img src={contactSideimg} alt="nails" />
        </div>

        <div className="right">
          <div className="title">get in touch</div>
          <p className="desc">
            Davi Nails is located inside Walmart Supercenter. We provide
            affordable high quality nail services with outstanding friendly
            service with the best nail artists in the industry.
          </p>
        </div>

        <img src={quad} alt="quad-icon" />
        <img src={spikes} alt="spikes-icon" />
      </div>

      <div className="contact-form">
        <div className="details">
          <Link to="tel:+17704220211" className="call">
            <img src={call} alt="call-icon" />
            <p>770-422-0211</p>
          </Link>
          <div className="mail">
            <img src={mail} alt="mail-icon" />
            <p>DaviNailsMarietta@Outlook.com</p>
          </div>
          <Link
            to="https://goo.gl/maps/UwnVL8ZRbPioaNXa7"
            target="_blank"
            className="location"
          >
            <img src={location} alt="location-icon" />
            <p>
              Davi Nails Salon Located Inside Walmart 210 Cobb Parkway S
              Marietta GA 30060
            </p>
          </Link>
        </div>

        <div className="form">
          <h3>We’d love to hear from you</h3>
          <p>
            Visiting our nail salon in Marietta GA is a great way to pamper
            yourself and feel refreshed at a fraction of the cost of other
            salons. You can choose from a variety of services.
          </p>

          <form ref={form} onSubmit={sendEmail}>
            <input type="text" placeholder="Your Name*" name="name" required />
            <input type="number" placeholder="Phone*" name="phone" required />
            <input
              type="email"
              placeholder="Email Address*"
              name="email"
              required
            />
            <input
              type="text"
              placeholder="Your Message"
              name="message"
              required
            />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>

      {/* MAP */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.8232080509993!2d-84.52446612511856!3d33.945674923351355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f51371bcc5da77%3A0xf43b0769ef54a083!2sDavi%20Nails%20Salon!5e0!3m2!1sen!2s!4v1692645028743!5m2!1sen!2s"
        width="100%"
        height="450"
        style={{ border: 0, marginBottom: "20px" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Contact;
