import React, { useEffect, useRef, useState } from "react";
import { menuLink } from "../../assets/data/menuLinks";
import { Link, NavLink } from "react-router-dom";
import "./navigation-menu.scss";
import call from "../../assets/images/call-black.png";
import bars from "../../assets/images/bars.svg";
import cut from "../../assets/images/cut.svg";
import { useLocation } from "react-router-dom";


const NavigationMenu = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(()=>{
    setOpen(false)
  }, [location.pathname])

  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div ref={menuRef} className="navigation-menu">
      <div className="main">
        <ul className="desktop-links">
          {menuLink.map((item, index) => (
            <li>
              <NavLink to={item.path}>{item.name}</NavLink>
            </li>
          ))}
        </ul>

        <ul className={open ? "mobile-links show" : "mobile-links"}>
          {menuLink.map((item, index) => (
            <li>
              <NavLink to={item.path}>{item.name}</NavLink>
            </li>
          ))}
        </ul>

        <Link to="tel:+17704220211" className="call-btn">
          <p>Call Now</p>
          <img src={call} alt="call-icon" />
        </Link>

        <div className="nav-icons">
          {open === false ? (
            <img src={bars} alt="bars" onClick={() => setOpen(true)} />
          ) : (
            <img src={cut} alt="cut" onClick={() => setOpen(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
