import tMale from "../../assets/images/t-male.png";
import tFemale from "../../assets/images/t-female.png";

export const testimonials = [
  {
    review:
      "I've had the pleasure of having service with many of the nail techs there. They are all very professional and good at their jobs. They give me info on the service being done . I get the best pedicure there and my nails are so cute. Yes they do get busy but that's because they are good at what they do.",
    name: "Sydne Johnson",
    relation: "Client",
    img: tMale,
  },
  {
    review:
      "Love Davi Nails Salon! Their gel manicures are great and their staff is so nice and personable. The last gel manicure I got there lasted over 3 weeks and never chipped. I only got it redone because my nails had grown out so far and it was starting to look a little strange. I've had some great conversations with the nail artists there, it makes my day to go there! Linda is great!",
    name: "Amir Mohamad Zamani",
    relation: "Client",
    img: tMale,
  },
  {
    review:
      "THE BEST STAFF here! Friendly and do their jobs quite well. You cannot go wrong here. ⭐️⭐️⭐️ Yolanda runs a tight ship and only the best service for all customers. I receive compliments ALL THE TIME on my nails because Yolanda is a perfectionist for symmetry and nail shape. I’m ruined for any other salon! I’ve tried and my nails look like child’s play if done somewhere else, compared to Yolanda’s skill.",
    name: "Marjorie Williams",
    relation: "Client",
    img: tMale,
  },
  {
    review:
      "Absolutely loved Davi Nails Salon. I have had a hard time finding a nice nail place that is worth the price tag, but Davi Nails Salon is AMAZING! I went in with my mom and sister and we all loved it. We had 3 pedicures and did not have to wait at all for chairs. We enjoyed the massage chairs . Ashley was so kind and professional. Absolutely loved it. I'm going back to get my nails done for my party!",
    name: "Afzali niya",
    relation: "Client",
    img: tFemale,
  },
  {
    review:
      "Davi Nails Salon is my absolute favorite nail salon. The nail technicians did a very nice job on my manicure Tech took her time and I really enjoy the conversation. They did such a great job and the price was very reasonable. I have really enjoyed my visits here.",
    name: "Sara Afeef",
    relation: "Client",
    img: tFemale,
  },
  {
    review:
      "Davi Nails Salon is an oasis. the employees take time to delicately care for their clients. my deluxe pedicure exceeded all expectations-- the sugar scrub and massage were both so delightful. i was allowed to savor the experience, a total rarity in most nail salons nowadays. will definitely be coming back.",
    name: "Abou Bayor",
    relation: "Client",
    img: tMale,
  },
  {
    review:
      "I finally found my favorite salon. I came here to get my nails done and my nail tech did an exceptional job. She was patient and attentive to me. Tina did not hesitate to meet all my request and did a great job on my nail for New Year. ❤️",
    name: "Tinnie P Flomo",
    relation: "Client",
    img: tFemale,
  },
  {
    review:
      "My experience at the salon was great. Elisia did my nails. She was very gentle and very professional. We even had a personal conversation. I thought my nails looked great, but she went above and beyond of the call of her duty. My nails look amazing and I will be returning soon",
    name: "RANJAN RAJ",
    relation: "Client",
    img: tMale,
  },
  {
    review:
      "I LOVE coming to Davi Nails Salon! They are so friendly and know who I am when I walk through the door! They're super fast and up to date with all the new and cool nail techniques!",
    name: "Lety Diomandé",
    relation: "Client",
    img: tFemale,
  },

];
