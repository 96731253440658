import m1 from "../images/services/m1.svg"
import m2 from "../images/services/m2.svg"
import m3 from "../images/services/m3.svg"
import m4 from "../images/services/m4.svg"
import m5 from "../images/services/m5.svg"
import m6 from "../images/services/m6.svg"
import m7 from "../images/services/m7.svg"
import m8 from "../images/services/m8.svg"
import m9 from "../images/services/m9.svg"
import m10 from "../images/services/m10.svg"
import p1 from "../images/services/p1.svg"
import p2 from "../images/services/p2.svg"
import p3 from "../images/services/p3.svg"
import as1 from "../images/services/as1.webp"
import as2 from "../images/services/as2.webp"
import as3 from "../images/services/as3.webp"

export const services = [
  // Manicure Services
  {
    img: m1,
    name: "Manicure Service",
    desc: "Beautiful affordable manicure with filing, buffing and a wide range of color options",
    price: "$20",
    id: "manicure-service",
  },
  {
    img: m2,
    name: "Acrylic Full Set",
    desc: "Custom sculpted acrylic nails, perfect shape, and a wide range of color options for a beautiful full set",
    price: "$30 & Up",
    id: "manicure-service",
  },
  {
    img: m3,
    name: "Gel Manicure",
    desc: "A beautiful gel manicure service with a glossy, long-lasting finish and a wide range of color options that will make your nails look elegant and polished",
    price: "$30",
    id: "manicure-service",
  },
  {
    img: m4,
    name: "Solar Pink & White",
    desc: "Solar pink & white manicure with a perfect blend of pink and white for a natural-looking French manicure, with a glossy finish",
    price: "$50 & Up",
    id: "manicure-service",
  },
  {
    img: m5,
    name: "SNS Powder",
    desc: "SNS Powder manicure with a flawless and natural-looking finish, various color options and a glossy finish that last for weeks",
    price: "$45 & Up",
    id: "manicure-service",
  },
  {
    img: m6,
    name: "Ombre",
    desc: "Ombre manicure is a technique in which two or more colors are gradually blended together to create a gradient effect on the nails. It creates a beautiful, unique and trendy look on the nails",
    price: "$45 & Up",
    id: "manicure-service",
  },
  {
    img: m7,
    name: "Cat Eye",
    desc: "Cat Eye nails feature a mesmerizing, swirling pattern created with special magnetic gel polish for a unique and trendy look",
    price: "$15 & Up",
    id: "manicure-service",
  },
  {
    img: m8,
    name: "Chrome ",
    desc: "Amazing Chrome nails are achieved with a mirror-like finish and various shades and colors, creating a stunning effect that is sure to turn heads",
    price: "$20 & Up",
    id: "manicure-service",
  },
  {
    img: m9,
    name: "Deluxe Manicure",
    desc: "Indulge in a luxurious manicure service with expert shaping, buffing, polishing, cuticle care and a wide range of color options in a relaxing spa atmosphere",
    price: "$43",
    id: "manicure-service",
  },
  {
    img: m10,
    name: "Deluxe Manicure & Pedicure",
    desc: "Enjoy the best of world worlds and indulge yourself with an amazing deluxe manicure and pedicure!",
    price: "$75",
    id: "manicure-service",
  },
  //   Pedicure-Services
  {
    img: p1,
    name: "Pedicure",
    desc: "Pedicure service with cleaning, exfoliation, nail care and polish for refreshed feet",
    price: "$27",
    id: "pedicure-service",
  },
  {
    img: p2,
    name: "Gel Pedicure",
    desc: "Gel pedicure service with a glossy, long-lasting finish, and a wide range of color options for polished and elegant looking feet",
    price: "$40 & Up",
    id: "pedicure-service",
  },
  {
    img: p3,
    name: "Deluxe Pedicure",
    desc: "Deluxe gel pedicure service includes a foot soak, exfoliation, massage, and a glossy, long-lasting gel polish in a wide range of colors for the ultimate spa experience",
    price: "$45",
    id: "pedicure-service",
  },
  //   Additional-Services
  {
    img: as1,
    name: "Designs / Art / Stones",
    price: "$7 & Up",
    id: "additional-service",
  },
  {
    img: as2,
    name: "Nail Shaping",
    price: "$7 & Up",
    id: "additional-service",
  },
  {
    img: as3,
    name: "Designs / Art / Stones",
    price: "$7 & Up",
    id: "additional-service",
  },
];
