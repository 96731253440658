import React, { useEffect, useState } from "react";
import "./blog-detail-page.scss";
import spikes from "../../assets/images/spikes.svg";
import quad from "../../assets/images/quad.svg";
import male from "../../assets/images/t-male.png";
import { blogs } from "../../assets/data/blogs";
import { useParams } from "react-router-dom";
import CommonSection from "../../components/common-section/CommonSection";
import Helmet from "../../components/helmet/Helmet";

const BlogDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(blogs.find((item) => item.id === id));

  useEffect(() => {
    let newData = blogs.find((item) => item.id === id);
    setData(newData);
  }, []);

  const {
    img,
    metaDesc,
    title,
    postDate,
    name,
    startingLine,
    points,
    closingLine1,
    closingLine2,
  } = data;

  return (
    <>
      <Helmet title={id} desc={metaDesc} canonical={"https://walmartnails.com/nail-insights/top-10-ways-to-protect-your-nails-to-ensure-long-lasting-beauty"}/>
      <CommonSection title={id.replace(/-/g, " ")} />

      <div className="blog-detail-page">
        <div className="bg-pattern"></div>
        <div className="bg-pattern2"></div>

        <img src={img} alt="thumbnail" />

        <h1 className="title">{title}</h1>

        <div className="info">
          <div className="left">
            <div className="name">
              <img src={male} alt="person" />
              <p>{name}</p>
            </div>
            <p className="date">
              Date . <span>{postDate}</span>
            </p>
          </div>

          <div className="right">
            <p>Location</p>
            <p>
              Davi Nails Salon Located Inside Walmart 210 Cobb Parkway SMarietta
              GA 30060
            </p>
          </div>
        </div>

        <div className="content">
          <p className="heading">{startingLine}</p>

          <ul>
            {points.map((item, index) => (
              <li>
                <span>{item.bold}</span> {item.normal}
              </li>
            ))}
          </ul>

          <p className="closing-line-one">{closingLine1}</p>
          <p className="closing-line-two">{closingLine2}</p>
        </div>

        <img src={spikes} alt="spikes-icon" />
        <img src={quad} alt="quad-icon" />
      </div>
    </>
  );
};

export default BlogDetailPage;
