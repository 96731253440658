export const heroSlides = [
    {
        heading: "The Best Go-To Nail Salon in Marietta, Georgia",
        subHeading: "Where Artistry Meets Elegance - Discover the Ultimate Nail Salon Experience"
    },
    {
        heading: "Nail Perfection, Crafted Just for You",
        subHeading: "Unveil Your Inner Glamour with Our Tailored Nail Creations"
    },
    {
        heading: "Nails Transformed, Beauty Redefined",
        subHeading: "Step into a World of Color, Creativity, and Chic Nail Couture"
    },
]