import React from "react";
import "./treatment-card.scss";
import { Link } from "react-router-dom";

const TreatmentCard = ({ img, title, desc, link }) => {
  return (
    <div className="treatment-card">
      <div className="image">
        <img src={img} alt={`${title}-img`} />
      </div>

      <div className="content">
        <h3>{title}</h3>
        <p>{desc}</p>
        <Link to={link}>View details</Link>
      </div>
    </div>
  );
};

export default TreatmentCard;
