import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/home/Home'
import OurStory from '../pages/our-story/OurStory'
import Services from '../pages/services/Services'
import Testimonials from '../pages/testimonials/Testimonials'
import NailInsights from '../pages/nail-insights/NailInsights'
import Contact from '../pages/contact-us/Contact'
import BlogDetailPage from '../pages/blog-detail-page/BlogDetailPage'
import NoMatch from '../pages/no-match/NoMatch'
import Smyrna from '../pages/offPageSeo/Smyrna'
import Kenessaw from '../pages/offPageSeo/Kenessaw'
import Atlanta from '../pages/offPageSeo/Atlanta'

const Routers = () => {
  return (
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/our-story' element={<OurStory />} />
        <Route path='/services' element={<Services />} />
        <Route path='/testimonials' element={<Testimonials />} />
        <Route path='/nail-insights' element={<NailInsights />} />
        <Route path='/nail-insights/:id' element={<BlogDetailPage />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='*' element={<NoMatch />} />

        <Route path='/smyrna' element={<Smyrna />} />
        <Route path='/kenessaw' element={<Kenessaw />} />
        <Route path='/atlanta' element={<Atlanta />} />
      </Routes>
  )
}

export default Routers