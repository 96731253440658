import React from "react";
import "./testimonial-section.scss";
import testimonialMain from "../../assets/images/testimonial-main.webp";
import { testimonials } from "../../assets/data/testimonials";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import TestimonialCard from "../testimonial-card/TestimonialCard";
import spikesRed from "../../assets/images/spikes-red.svg";

const TestimonialSection = () => {
  return (
    <div className="testimonial-section">
      <div className="left">
        <h3>CLIENT REVIEWS</h3>
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          direction={"vertical"}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination, Autoplay]}
          className="testimonial-slider"
        >
          {testimonials.map((item, index) => (
            <SwiperSlide>
              <TestimonialCard
                img={item.img}
                review={item.review}
                name={item.name}
                relation={item.relation}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="right">
        <img src={testimonialMain} alt="testimonial-main" />
        <img src={spikesRed} alt="spikes-icon" />
      </div>
    </div>
  );
};

export default TestimonialSection;
