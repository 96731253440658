import React from "react";

const Helmet = ({ title, desc, canonical }) => {
  React.useEffect(() => {
    document.title = title;

    const metaDesc = document.querySelector('meta[name="description"]');
    if (metaDesc) {
      metaDesc.content = desc;
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = desc;
      document.head.appendChild(meta);
    }

    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute("href", canonical);
    } else {
      const link = document.createElement("link");
      link.rel = "canonical";
      link.setAttribute("href", canonical);
      document.head.appendChild(link);
    }

    return () => {
      document.title = "Top Rated Nail Salon & Spa in Marietta, GA";
      if (metaDesc) {
        metaDesc.content = "Get yourself pampered and refreshed at one of the best nail salon in Marietta, Georgia. We offer complete nail spa with skilled technicians. Call us at (770) 422-0211";
      }
      const canonicalLink = document.querySelector('link[rel="canonical"]');
      if (canonicalLink) {
        canonicalLink.removeAttribute("href");
      }
    };
  }, [title, desc, canonical]);

  return <></>;
};

export default Helmet;
