import React from "react";
import "./common-section.scss";

const CommonSection = ({ title, useAsHeading }) => {
  return (
    <div className="common-section">
      {useAsHeading ? <h1>{title}</h1> : <h4>{title}</h4>}
    </div>
  );
};

export default CommonSection;
