import React from "react";
import CommonSection from "../../components/common-section/CommonSection";
import ContactSection from "../../components/contact-section/ContactSection";
import Helmet from "../../components/helmet/Helmet";

const NoMatch = () => {
  return (
    <div>
      <Helmet title={"Page Not Found"} />
      <CommonSection title={"Page Doesn't exist"} />
      <ContactSection />
    </div>
  );
};

export default NoMatch;
