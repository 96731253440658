import React, { useRef } from "react";
import "./contact-section.scss";
import spikesRed from "../../assets/images/spikes-red.svg"
import Swal from 'sweetalert2'
import emailjs from '@emailjs/browser'

const ContactSection = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
      emailjs.sendForm('service_on5nidn', 'template_qwipmbh', form.current, 'bHKmzUvcOZ1zl1Pnw')
          .then((result) => {
            Swal.fire({
              icon: 'success',
              title: 'Email Sent Successfully!',
              text: 'Thank You For Contacting Us! Our team will be in touch with you soon.',
              showCloseButton: true,
              showConfirmButton: false,
              timer: 3000
            })
              form.current.reset()
          }, (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Try Again!',
              text: 'Something went wrong, Please Try Again',
              showCloseButton: true,
              showConfirmButton: false,
              timer: 3000
            })
          });
  };

  return (
    <div className="contact-section">
      <div className="content">
        <h3>contact us</h3>
        <p>Please fill out this form below and we’ll get back to you soon</p>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" placeholder="Your Name*" name="name" required />
          <input type="number" placeholder="Phone*" name="phone" required />
          <input type="email" placeholder="Email Address*" name="email" required />
          <input type="text" placeholder="Your Message" name="message" required />
          <button type="submit">Submit</button>
        </form>
      </div>
      <img src={spikesRed} alt="spikes-icon" />
    </div>
  );
};

export default ContactSection;
