import React from "react";
import "./service-card.scss";

const ServiceCard = ({ img, name, desc, price }) => {
  return (
    <div className="service-card">
      <img src={img} alt={name} />
      <h4>{name}</h4>
      <p className="service-desc">{desc}</p>
      <p className="price">{price}</p>
    </div>
  );
};

export default ServiceCard;
