import React from "react";
import "./testimonials.scss";
import CommonSection from "../../components/common-section/CommonSection";
import testimonialSideimg from "../../assets/images/testimonial-sideimg.webp";
import { testimonials } from "../../assets/data/testimonials";
import TestimonialCard from "../../components/testimonial-card/TestimonialCard";
import quad from "../../assets/images/quad.svg";
import spikes from "../../assets/images/spikes.svg";
import Helmet from "../../components/helmet/Helmet";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <Helmet title={"Testimonials"} canonical={"https://walmartnails.com/testimonials"}/>
      <CommonSection title={"Testimonial"} />

      {/* SECTION-1 */}
      <div className="section t-section">
        <div className="bg-pattern"></div>
        <div className="left">
          <img src={testimonialSideimg} alt="dotted-print-nails" />
        </div>

        <div className="right">
          <h1 className="title">
            The Go-To Nail Salon in Marietta Best Value in Atlanta
          </h1>
          <h2 className="mid">BY TRUSTED NAIL ARTISTS</h2>
          <p className="desc">
            Visiting our nail salon in Marietta GA is a great way to pamper
            yourself and feel refreshed at a fraction of the cost of other
            salons. You can choose from a variety of services, including
            manicures, pedicures, and nail art, and our skilled technicians will
            ensure that you leave with beautiful, polished nails. Plus, our
            salon uses high-quality products and implements strict sanitation
            protocols to ensure a safe and relaxing experience for all of our
            clients.
          </p>
        </div>

        <img src={spikes} alt="spikes-icon" />
        <img src={quad} alt="quad-icon" />
      </div>

      {/* TESTIMONIALS */}
      <div className="all-testimonials">
        {testimonials.map((item, index) => (
          <TestimonialCard
            img={item.img}
            review={item.review}
            name={item.name}
            relation={item.relation}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
