import React from 'react'
import "./info-nav.scss"

const InfoNav = () => {
  return (
    <div className='info-nav'>
        <p>Walk-in's Welcome / No Appointment Needed</p>
    </div>
  )
}

export default InfoNav