import React from "react";
import "./hero.scss";
import { Link } from "react-router-dom";
import { heroSlides } from "../../assets/data/heroSlides";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay } from "swiper/modules";

const Hero = () => {
  return (
    <div className="hero">
      <div className="content">
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="hero-slider"
        >
          {heroSlides.map((item, index) => (
            <SwiperSlide>
              <>
                {index === 0 ? (
                  <h1>{item.heading}</h1>
                ) : (
                  <h2>{item.heading}</h2>
                )}
                <p>{item.subHeading}</p>
                <button>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </button>
              </>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Hero;
