import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Routers from "../../routers/Routers";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import InfoNav from "../info-nav/InfoNav";
import NavigationMenu from "../navigation-menu/NavigationMenu";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <InfoNav />
      <Header />
      <NavigationMenu />
      <div>
        <Routers />
      </div>
      <hr
        style={{
          width: "80%",
          margin: "auto",
          backgroundColor: "#7e7e7e",
          opacity: 0.3,
          // marginTop: '50px'
        }}
      />
      <Footer />
    </div>
  );
};

export default Layout;
